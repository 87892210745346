<template>
  <div class="DebtorCredit">
    <debtor-credit-history />
  </div>
</template>

<script>
import DebtorCreditHistory from './debtor-credit-history.vue'

export default {
  name: 'DebtorCredit',

  components: {
    DebtorCreditHistory,
  },
}
</script>

<style lang="sass">
.DebtorCredit
  margin-top: rem(42px)
  padding: 0 rem(29px) 2.5rem
</style>