<template>
  <div class="DebtorAccountCreditHistory">
    <div class="column">
      <!-- Credit Update MODAL -->
      <div class="DebtorAccountCreditHistory__credit-section-heading row row--align-center row--justify-between">
        <!-- If the external rating for the debtor is "F," you cannot update the rating -->
        <div class="column column--width-auto">
          <label :class="['fs-16 fw-med', { 'mb-14': canAddRating }]">Rating</label>
          <label
            v-if="!canAddRating"
            class="fs-14 mb-14"
          >
            The rating cannot be updated because our reported external rating for this debtor is a "F"
          </label>
        </div>
        <base-button
          v-if="canAddRating"
          @click="showRating = !showRating"
          class="bg-blue fc-white"
          data-cy="add-debtor-history-rating"
        >
          Add Rating
        </base-button>

        <!-- CREDIT UPDATE MODAL -->
        <transition name="Transition__fade">
          <div
            v-if="showRating"
            class="DebtorAccountCreditHistory__credit-modal row row--align-end row--width-auto"
          >
            <div class="column column--width-auto">
              <label class="fc-light fs-12 uppercase mb-7">Credit Rating</label>
              <v-select
                v-model="newCreditRating"
                aria-label="Select input to choose a credit rating"
                class="DebtorAccountCreditHistory__credit-select mr-6"
                :clearable="false"
                :options="creditOptions"
                :placeholder="'Rating'"
                data-cy="select-debtor-history-rating"
              />
            </div>
            <base-input
              v-model.trim="creditReason"
              class="mr-6"
              :label="true"
              :label-for="'credit-reason'"
              :placeholder="'Credit reason'"
              data-cy="reason-debtor-history-rating"
            >
              Reason for Rating
            </base-input>
            <base-button
              @click="updateCredit"
              @keydown.enter="updateCredit"
              class="bg-blue fc-white mr-6"
              :disabled="submitButtonDisabled"
              data-cy="submit-debtor-history-rating"
            >
              Submit
            </base-button>
            <base-button
              @click="showRating = false"
              @keydown.enter="showRating = false"
              class="bg-light fc-white"
            >
              Cancel
            </base-button>
          </div>
        </transition>
      </div>

      <!-- Table Header of Credit History -->
      <div class="row">
        <label class="DebtorAccountCreditHistory__table-header fc-light fs-12 uppercase">
          Date
        </label>
        <label class="DebtorAccountCreditHistory__table-header fc-light fs-12 uppercase">
          Rating
        </label>
        <label class="DebtorAccountCreditHistory__table-header fc-light fs-12 uppercase">
          Notes
        </label>
      </div>

      <!-- Table of Credit History -->
      <div
        v-for="(credit, index) in debtor.metadata.rating_history"
        :key="index"
        class="DebtorAccountCreditHistory__rating-wrapper row row--align-center"
      >
        <time
          class="DebtorAccountCreditHistory__table-header fs-14"
          :datetime="credit.updated_at | datetime('YYYY-MM-DD hh:mm:ssZ')"
        >
          {{ credit.updated_at | datetime('MM/DD/YYYY') }}
        </time>
        <div class="DebtorAccountCreditHistory__table-header">
          <base-debtor-rating
            :rating="credit.display_rating"
            :size="'medium'"
            :id="`Testing__DebtorHistoryRating-${index}`"
          />
        </div>
        <label class="DebtorAccountCreditHistory__table-header fc-mid fs-14">
          {{ credit.rating_reason }}
          (Changed by {{
            credit.user_description || "System"
          }})
        </label>
      </div>
    </div>
  </div>
</template>

<script>
// Helpers
import { Debtor } from '../../../utils/api'
// Components
import BaseButton from '../../../components/base-button.vue'
import BaseDebtorRating from '../../../components/base-debtor-rating.vue'
import BaseInput from '../../../components/base-input.vue'

export default {
  name: 'DebtorAccountCreditHistory',

  components: {
    BaseButton,
    BaseDebtorRating,
    BaseInput,
  },

  async created () {
    // Debtors with an external rating of F are not allowed to be updated (with a Bobtail rating)
    if (this.debtor.external_rating === 'F') this.canAddRating = false;
    await this.getCreditHistory()
  },

  data () {
    return {
      canAddRating: true,
      creditOptions: ['A', 'B', 'C', 'D', 'F'],
      creditReason: '',
      newCreditRating: '',
      showRating: false,
      submitButtonDisabled: false,
    }
  },

  computed: {
    debtor () {
      return this.$store.getters.debtor
    },

  },

  methods: {
    async getCreditHistory () {
      // external_rating indicates the rating imported from the debtor upload spreadsheet
      // rating is Bobtail's internal rating
      // Get the history of the `rating` and `external_rating`
      // Combine them and sort by time
      if (this.$store.getters.debtor.external_rating !== '--') {
        this.creditOptions = [this.$store.getters.debtor.external_rating, 'F']
      }
    },
    async updateCredit () {
      this.submitButtonDisabled = true
      this.progressStart()

      try {
        const debtor = (await Debtor.update({
          id: this.debtor.id,
          rating: this.newCreditRating,
          rating_reason: this.creditReason,
        })).data
        this.$store.commit('UPDATE_STORED_DEBTOR', debtor)
        await this.getCreditHistory()
        this.showRating = false
        this.submitButtonDisabled = false
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Debtor Credit History "Update Credit"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue updating the debtor' })
      }
    },
  },
}
</script>

<style lang="sass">
.DebtorAccountCreditHistory

  .BaseDebtorRating
    height: rem(32px)
    margin: 0
    width: rem(32px)

  &__credit-modal
    background-color: $white
    border: $border
    border-radius: $border-radius
    padding: rem(25px) rem(31px)
    position: absolute
    right: 0
    top: rem(49px) // 38px btn height + 11px of space

  &__credit-section-heading
    position: relative

  &__credit-select
    width: rem(150px)

    .vs__selected
      &:before
        content: 'Rating:'
        padding-right: rem(4px)

  &__rating-wrapper
    padding: rem(20px) 0

  &__table-header
    &:nth-child(1)
      flex: 0 0 rem(100px)
      margin-right: 1rem
    &:nth-child(2)
      flex: 0 0 rem(100px)
      margin-right: 1rem
    &:nth-child
      flex: 1 0 0
</style>